import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import party from '@/app/components/generalLedger/party';
import DatePicker from 'vue2-datepicker';
import CreateBill from './createBill';
import BillingDetails from './billingDetails';
import CreateNewBill from './createNewBill';
import URL_UTILITY from '../../../../utility/url.utility';
import ViewTransaction from '../../../arReceivables/arTransactions/transaction/viewTransaction';
export default {
  name: 'Billing',
  components: {
    CreateBill,
    DatePicker,
    BillingDetails,
    CreateNewBill,
    ViewTransaction,
    party
  },
  watch: {
    currentPage: function() {
      this.searchParameter.pagination = {
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.searchBill(this.searchParameter);
    },
    perPage: function() {
      this.currentPage = 1;
      this.searchParameter.pagination = {
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.searchBill(this.searchParameter);
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    },
    selectAllHoldCheckBox: function() {
      this.checkUncheckAllHold();
    }
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      showAdvanceSearch: false,
      searchParameter: {},
      showBillingDetailsModel: false,
      enableCreateBilling: false,
      billDetails: null,
      loader: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      showCreateBillModal: false,
      billNumber: null,
      moduleId: this.$store.state.shared.moduleId,
      billNature: { text: 'FINAL', value: 'FINAL' },
      trxType: { text: null, value: null },
      project: { text: null, value: null },
      salesAgreement: {
        id: null,
        text: null
      },
      customer: {
        name: null,
        id: null
      },
      customerSite: {
        name: null,
        id: null
      },
      legalEntity: { text: null, value: null },
      operatingUnit: { text: null, value: null },
      billStatus: { text: null, value: null },
      source: { text: null, value: null },
      glStartDate: null,
      glEndDate: null,
      billPeriodFrom: null,
      billPeriodTo: null,
      dueStartDate: null,
      dueEndDate: null,
      defaultValue: { text: null, value: null },
      downloadLog: URL_UTILITY.getDownloadLOGUrl,
      billingData: [],
      billingFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'selectHoldBox',
          label: 'Hold/Un-hold',
          // stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'bill_status_meaning',
          label: 'Bill Status'
        },
        {
          key: 'bill_nature'
        },
        {
          key: 'document_num',
          label: 'Bill No.'
        },
        {
          key: 'billing_plan_name'
        },
        {
          key: 'customer_name',
          label: 'Customer'
        },
        {
          key: 'customer_site_name',
          label: 'Customer Site'
        },
        {
          key: 'customer_site_address'
        },
        {
          key: 'bill_status_meaning',
          label: 'Bill Status'
        },
        {
          key: 'total_amount',
          label: 'Total Amount'
        },
        {
          key: 'period_start_date'
        },
        {
          key: 'period_end_date'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'doc_gen_date',
          label: 'Bill Date'
        },
        {
          key: 'due_date'
        },
        {
          key: 'project_name'
        },
        {
          key: 'sales_agreement_no',
          label: 'Sales Agmt No.'
        },
        {
          key: 'first_party_gstn'
        },
        {
          key: 'third_party_gstn'
        },
        {
          key: 'value_date'
        },
        {
          key: 'trx_number'
        },
        {
          key: 'request_id'
        },
        {
          key: 'error_msg'
        },
        {
          key: 'module_name'
        },
        {
          key: 'trx_type'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'unit_name',
          label: 'Unit'
        },
        {
          key: 'fms_sub_unit_name',
          label: 'Sub Unit'
        },
        {
          key: 'customer_id'
        },
        {
          key: 'party_id'
        },
        {
          key: 'bill_cat_vset_meaning',
          label: 'Bill Category'
        },
        // {
        //   key: 'ar_prj_receipt_method_mpng_name',
        //   label: 'Receipt Method Name'
        // }
      ],
      modalTitle: null,
      selectAllCheckBox: false,
      selectAllHoldCheckBox: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: null,
      showViewTransaction: false,
      trxHdrId: null,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        }
      ],
      showPartyModal: false,
      showCustomerSiteModal: false,
      payload: {},
      billCat: { value: null, text: null }
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showHideCreateBillModal(true, 'add');
          this.billDetails = null;
        }
        if (actionName === 'save') {
          if (this.billNature.value === 'PROVISIONAL') {
            this.generateFinalBill();
          } else {
            this.billingRequestGenerate();
          }
        }
        if (actionName === 'download' && this.billingData.length) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'fms/getFmsBills',
            'fms-Bill-Details',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    searchBill() {
      if (this.showAdvanceSearch && !this.source.value) {
        alert('Please select Source as it is mandatory.');
      } else if (
        (!this.billNumber &&
          !this.project.value &&
          !this.trxType.value &&
          !this.showAdvanceSearch) ||
        !this.billPeriodFrom ||
        !this.billPeriodTo
      ) {
        alert('Please select Mandatory filter.');
      } else {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          customer_id: this.customer.id,
          customer_site_id: this.customerSite.id,
          bill_status_vset: this.billStatus.value,
          document_num: this.billNumber,
          due_start_date: this.dueStartDate,
          due_end_date: this.dueEndDate,
          gl_start_date: this.glStartDate,
          gl_end_date: this.glEndDate,
          le_id: this.legalEntity.value,
          ou_id: this.operatingUnit.value,
          period_start_date: this.billPeriodFrom,
          period_end_date: this.billPeriodTo,
          project_id: this.project.value,
          source_hdr_id: this.source.value,
          trx_type_dtl_id: this.trxType.value,
          bill_nature: this.billNature.value,
          sales_agreement_hdr_id: this.salesAgreement.id,
          bill_cat_vset: this.billCat.value
        };
        this.loader = true;
        this.$store
          .dispatch('fms/getFmsBills', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.showHideAdvanceSearch(false);
              this.billingData = response.data.data.page;
              this.billingData = this.billingData.map(ele => {
                // if (ele.is_hold_flag) {
                ele.selectHoldBox =
                  ele.bill_status_meaning !== 'PROCESSED' && ele.is_hold_flag;
                return ele;
                // }
              });
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    generateFinalBill() {
      const selectedRows = this.billingData.filter(data => data.selectBox);
      if (selectedRows && selectedRows.length > 0) {
        const prepareData = selectedRows.map(data => {
          return {
            bill_hdr_tmp_id: data.bill_hdr_id
          };
        });
        const payload = {
          bill_hdr_tmp: prepareData
        };
        this.loader = true;
        this.$store
          .dispatch('fmsTransaction/generateFinalBill', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.searchBill();
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    billingRequestGenerate() {
      const selectedRows = this.billingData.filter(data => data.selectBox);
      if (selectedRows && selectedRows.length > 0) {
        const prepareData = selectedRows.map(data => {
          return {
            id: data.bill_hdr_id
          };
        });
        const payload = {
          bill_id: prepareData
        };
        this.loader = true;
        this.$store
          .dispatch('fmsTransaction/billingRequestGenerate', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    selectBoxChecked(flag, index) {
      this.billingData[index].selectBox = flag;
    },
    checkUncheckAll() {
      this.billingData = this.billingData.map(data => {
        if (data.request_id) {
          if (!/e/i.test(data.bill_status_vset)) {
            data.selectBox = false;
          } else {
            data.selectBox = this.selectAllCheckBox;
          }
        } else {
          data.selectBox = this.selectAllCheckBox;
        }
        return data;
      });
    },
    checkUncheckAllHold() {
      this.billingData = this.billingData.map(data => {
        data.selectHoldBox =
          data.bill_status_meaning !== 'PROCESSED' &&
          this.selectAllHoldCheckBox;
        return data;
      });
      // this.updateBillHoldUnhold()
    },
    updateBillHold() {
      const prompt = confirm('Are you sure you wan to Hold');
      if (prompt) {
        const checkHoldUnhold = this.billingData.filter(
          key => key.selectHoldBox
        );
        if (checkHoldUnhold.length) {
          const holdUnhold = checkHoldUnhold.map(key => {
            return {
              bill_id: key.bill_hdr_id,
              bill_nature: key.bill_nature,
              is_hold_flag: key.selectHoldBox
            };
          });
          const payload = {
            is_hold: holdUnhold
          };
          this.loader = true;
          this.$store
            .dispatch('fmsTransaction/updateBillHoldUnhold', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg = response.data.message;
                this.searchBill();
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      }
    },
    updateBillUnhold() {
      const prompt = confirm('Are you sure you wan to Un-Hold');
      if (prompt) {
        const checkHoldUnhold = this.billingData.filter(
          key => !key.selectHoldBox
        );
        if (checkHoldUnhold.length) {
          const holdUnhold = checkHoldUnhold.map(key => {
            return {
              bill_id: key.bill_hdr_id,
              bill_nature: key.bill_nature,
              is_hold_flag: key.selectHoldBox
            };
          });
          const payload = {
            is_hold: holdUnhold
          };
          this.loader = true;
          this.$store
            .dispatch('fmsTransaction/updateBillHoldUnhold', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg = response.data.message;
                this.searchBill();
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      }
    },
    validateCheckBox(row) {
      if (row.request_id) {
        if (!/e/i.test(row.bill_status_vset)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    showHideCreateBillModal(flag, modalTitle) {
      this.showCreateBillModal = flag;
      this.modalTitle = modalTitle;
      if (!flag) {
        this.searchBill();
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.TRX_TYPE) {
        this.parent_value_set_id = this.moduleId;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.TRX_TYPE) {
        this.trxType = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILL_NATURE) {
        this.billNature = {
          text: item.value_meaning,
          value: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.project = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.operatingUnit = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.project = this.defaultValue;
        this.operatingUnit = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_SEARCH_BY) {
        this.operatingUnit = {
          text: item.value_meaning,
          value: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILL_STATUS) {
        this.billStatus = {
          text: item.value_meaning,
          value: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SOURCE_LIST) {
        this.source = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SALES_AGREEMENT) {
        this.salesAgreement = {
          id: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILL_CAT_VSET) {
        this.billCat.text = item.value_meaning;
        this.billCat.value = item.value_code;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.trxType.value) {
        this.trxType = this.defaultValue;
      } else if (vsetCode === this.project.value) {
        this.project = this.defaultValue;
        this.legalEntity = this.defaultValue;
        this.operatingUnit = this.defaultValue;
      } else if (vsetCode === this.customer.id) {
        this.customer = { id: null, name: null };
        this.customerSite = { id: null, name: null };
      } else if (vsetCode === this.customerSite.id) {
        this.customerSite = { id: null, name: null };
      } else if (vsetCode === this.legalEntity.value) {
        this.legalEntity = this.defaultValue;
        this.operatingUnit = this.defaultValue;
      } else if (vsetCode === this.operatingUnit.value) {
        this.operatingUnit = this.defaultValue;
      } else if (vsetCode === this.billStatus.value) {
        this.billStatus = this.defaultValue;
      } else if (vsetCode === this.source.value) {
        this.source = this.defaultValue;
      } else if (vsetCode === this.billCat.value) {
        this.billCat.text = null;
        this.billCat.value = null;
      }
    },
    rowSelected(items) {
      this.showCreateBillModal = true;
      this.billDetails = items;
    },
    openTransactionDetails(hdrId) {
      this.trxHdrId = hdrId;
      this.showViewTransaction = true;
    },
    buttonAccessPermissions(actionName) {
      this.enableCreateBilling = true;
      setTimeout(() => {
        this.eventBus.$emit('createBillActions', actionName);
      }, 0);
    },
    clearFilters() {
      this.billNumber = null;
      this.trxType = this.defaultValue;
      this.project = this.defaultValue;
      this.legalEntity = this.defaultValue;
      this.operatingUnit = this.defaultValue;
      this.salesAgreement = this.defaultValue;
      this.billStatus = this.defaultValue;
      this.source = this.defaultValue;
      this.customer = { id: null, name: null };
      this.customerSite = { id: null, name: null };
      this.glEndDate = null;
      this.glStartDate = null;
      this.billPeriodFrom = null;
      this.billPeriodTo = null;
      this.dueEndDate = null;
      this.dueStartDate = null;
      this.billingData = [];
      this.totalRows = null;
      this.currentPage = 1;
      this.billCat = { value: null, text: null };
    },
    showHideAdvanceSearch(flag) {
      // if (flag) {
      //   this.clearFilters();
      // }
      this.showAdvanceSearch = flag;
    },
    truncateString(souce, size) {
      return commonHelper.truncate(souce, size);
    },
    showHidePartyModal(flag) {
      setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
      this.showPartyModal = flag;
    },
    getSelectedPartyCustomer(item) {
      this.customer.name = item.party_name;
      this.customer.id = item.party_id;
      this.customerSite.name = null;
      this.customerSite.id = null;
      this.showPartyModal = false;
      this.getCustomerByPartyId(item.party_id);
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.customer.id = response.data.data.customer_id;
            this.getCustomerSiteByPartyId();
          }
        });
    },
    showHideCustomerSiteModal(flag) {
      if (this.customer.name) {
        this.showCustomerSiteModal = flag;
      } else {
        alert('Please Select Customer First');
      }
    },
    getCustomerSiteByPartyId() {
      const payload = {
        customer_id: this.customer.id,
        ou_id: this.operatingUnit.value
      };
      this.gridDataSite = [];
      this.$store
        .dispatch('party/getCustomerSiteByOu', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedSite(item) {
      this.customerSite.name = item.site_name;
      this.customerSite.id = item.customer_site_id;
      this.showCustomerSiteModal = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
